@import "main";

.home-photos {
  width: calc(100vw - 4em);
  margin: 0 calc(50% - 50vw + 2em);
}

.photo-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  .photo-gallery-line {
    width: 100%;
    box-sizing: border-box;
  }

  .photo-gallery-figure{
    margin: 0;
    display: none;
  }
  .photo-gallery-figure:target{
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .photo-gallery-img{
      max-width: 100%;
      max-height: 100%;
      animation: fadein .3s;
    }
  }
  .photo-gallery-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    color: #fff;
  }
  a:hover {
    color: #fff !important;
  }
  @keyframes fadein{
    0%{
      transform: scale(0.2);
      opacity: 0.2;
    }
    100%{
      transform: scale(1);
      opacity: 1;
    }
  }
}

.contact-list {
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 0 0.5em;

  .contact-link {
    display: inline-block;
    padding: 0;
    font-size: 1.5em;
    &::before {
      content: none;
    }
  }
}
.icon-qiita::before, .icon-zenn::before, .icon-hazi::before {
  content: "";
  padding: 0 0 1em 1em;
  display: inline;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0);
}
.icon-qiita::before { background-image: url(/assets/img/brands/qiita.png) }
.icon-zenn::before { background-image: url(/assets/img/brands/zenn.svg) }
.icon-hazi::before { background-image: url(/assets/img/avatar_400.png) }
